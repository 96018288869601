import Swiper from 'swiper/bundle';
import { EffectFade } from 'swiper/modules';
window.Swiper = Swiper;
window.Testimonials = (paginationClass) => {
    return {
        swiper: null,
        init() {
            setTimeout(() => {
                this.swiper = new Swiper(this.$refs.swiperContainer, {
                    modules: [EffectFade],
                    loop: true,
                    spaceBetween: 100,
                    pagination: {
                        el: paginationClass,
                        clickable: true,
                        type: 'bullets',
                    },
                    fadeEffect: { 
                        crossFade: true,
                    },
                    effect: 'fade',
                    slidesPerView: 1,
                    autoplay: {
                        delay: 7000,
                    },
                    navigation: {
                        nextEl: this.$refs.swiperNext,
                        prevEl: this.$refs.swiperPrev,
                    },
                    a11y: true,
                });
            }, 0);
        },
    };
};
